import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import {
    setAuthenticated as setAuthenticatedAction,
    setUser as setUserAction
} from '../reducers/actions';
import { Switch, Route } from 'react-router-dom';
import { setOpen as setOpenAction } from '../reducers/actions/alerta';
import Menu from '../componentes/menu';
import axios from 'axios';
import { getHeaders } from '../request';
import Detalhes from '../componentes/interno/pesquisa/webconferencia/detalhes';
import {
    SttCircularProgress,
    SttAlerta,
    SttCacheManager,
    SttLoading,
    SttTranslateHook,
    SttNotificationManager,
    SttModal
} from '@stt-componentes/core';
import { Cache } from '@stt-componentes/cache';
import Erro401 from './erro/401';
import Webconferencia from './pesquisa/webconferencias';
import CadastroWebconferencia from './cadastro/webconferencias';
import CadastroCurso from './cadastro/cursos';
import Curso from './pesquisa/cursos';
import ListaPresenca from '../componentes/interno/listaPresenca';
import ListaEquipeParticipante from '../componentes/interno/listaEquipeParticipante';
import { temPerfil, temPermissaoRBAC } from '../security/acl';
import { CONSTANTES_PERFIS, PERMISSOES } from '../common/Constants';
import Administrativo from './administrativo';
import {
    setPesquisarEventosAndamento as setPesquisarEventosAndamentoAction
} from '../reducers/actions/webconferencias';
import { tratarUrl } from '../util';

const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    },
    customNotificationManager: {
        right: 120
    }
}));

const Interno = ({ setPesquisarEventosAndamento, pesquisarEventosAndamento, isAuthenticated, setAuthenticated, user, setUser, parametrosAlerta, parametrosCarregando, setOpen }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const [notifications, setNotifications] = useState([]);
    const [webconferencia, setWebconferencia] = useState();
    const [buscar, setBuscar] = useState(false);

    // Visualizar uma webconferência das notificações
    const [idWebconferenciaSelecionada, setIdWebconferenciaSelecionada] = useState(null);
    const [tituloWebconferencia, setTituloWebconferencia] = useState(null);
    const [itemBiblioteca, setItemBiblioteca] = useState(null);

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => {setWidth(window.innerWidth)});
        return () => {
            window.removeEventListener('resize', () => {setWidth(window.innerWidth)});
        }
    }, []);

    const isMobile = width <= 768;

    const fecharModalDetalhes = () => {
        setIdWebconferenciaSelecionada(null);
        setTituloWebconferencia(null);
        setItemBiblioteca(null);
    }

    useEffect(() => {
        if (pesquisarEventosAndamento) {
            pesquisarEventosEmAndamento();
            setPesquisarEventosAndamento(false);
        }
    }, [pesquisarEventosAndamento]);

    const pesquisarEventosEmAndamento = () => {
        let filtros = {};
        // Filtros implícitos
        filtros.permissaoWebsPublicas = temPermissaoRBAC(user, PERMISSOES.CADASTRAR_WEB);

        // Caso o usuário não possua permissão de ver web pública, pesquisar por
        // webconferências do seu público alvo ou as que não tem nenhum público alvo cadastrado
        if (!filtros.permissaoWebsPublicas && user.cbo && user.cbo[0]) {
            filtros.cboFamiliaUsuario = user.cbo[0].id_cbo_familia;
        }

        axios.get(`${global.gConfig.url_base_tele_educacao}/eventos-andamento`, { filtros, headers: getHeaders() })
            .then((response) => {
                if (response.data?.data) {
                    response.data.data.itens.forEach(i => {
                        i.url_webconferencia = tratarUrl(i.url_webconferencia);
                        if (i.codinome_instituicao) {
                            i.instituicaoFormatada = `${i.codinome_instituicao} (${i.nome_cidade}/${i.sigla_estado})`;
                        } else if (i.nome_instituicao) {
                            i.instituicaoFormatada = `${i.nome_instituicao} (${i.nome_cidade}/${i.sigla_estado})`;
                        }
                        i.onClick = () => setWebconferencia(formatarWebconferencia(i))
                    })

                    setNotifications(response.data.data.itens)
                }
            })
            .catch(err => {
                console.log(err);
                setNotifications([]);
            });
    }

    const formatarWebconferencia = (detalhes) => {
        if (detalhes?.enderecoSala?.includes(global.gConfig.url_padrao_conferencia_web)) {
            if (user?.email && user?.nome) {
                var parametros = [
                    `user[email]=${user.email}`,
                    `user[name]=${user.nome}`,
                ];
                detalhes.enderecoSala = `${detalhes.enderecoSala}/join?` + parametros.join('&');
                console.log('Endereço da sala interno', detalhes.enderecoSala);
            }
        }
        return detalhes;
    }

    useEffect(() => {
        if (isAuthenticated) {
            pesquisarEventosEmAndamento();
        }
    }, [isAuthenticated]);

    return (
        <>
            {
                isAuthenticated
                    ?
                    <div className={classes.wrapper}>
                        <Menu />
                        <Switch>
                            <Route path={["/", "/webconferencia"]} exact component={Webconferencia} />
                            <Route path="/cadastro/webconferencia" exact component={CadastroWebconferencia} />
                            <Route path="/cadastro/curso" exact component={CadastroCurso} />
                            <Route path="/pesquisa/curso" exact component={Curso} />
                            <Route path="/administrativo" exact component={Administrativo} />
                            <Route path="/nao-autorizado" exact component={Erro401} />
                        </Switch>

                        <SttAlerta
                            open={parametrosAlerta.open}
                            title={parametrosAlerta.titulo}
                            message={parametrosAlerta.mensagem}
                            type={parametrosAlerta.tipo}
                            onClose={() => {
                                if (parametrosAlerta.onClose) {
                                    parametrosAlerta.onClose();
                                    return;
                                }
                                setOpen(false);
                            }}
                            options={parametrosAlerta.opcoes || []}
                        />

                        {
                            temPermissaoRBAC(user, PERMISSOES.TELEEDUCACAO) &&
                            <SttNotificationManager notifications={notifications}
                                setIdWebconferenciaSelecionada={setIdWebconferenciaSelecionada}
                                setTituloWebconferencia={setTituloWebconferencia}
                                setItemBiblioteca={setItemBiblioteca}
                                startExpanded={!isMobile}
                                customClassName={user.perfisRBAC.some(p => p.identificador.includes(CONSTANTES_PERFIS.ATENDENTE_HELPDESK)) ? null : classes.customNotificationManager} 
                            />
                        }

                        <SttLoading
                            open={parametrosCarregando.open}
                            text={parametrosCarregando.titulo || strings.carregando}
                        />
                        {
                            webconferencia &&
                            <>
                                {
                                    temPerfil(user, CONSTANTES_PERFIS.VISUALIZADOR_WEB)
                                        ?
                                        <ListaPresenca idWebconferencia={webconferencia.id}
                                            urlWebconferencia={webconferencia.url_webconferencia}
                                            callback={() => setWebconferencia(null)} />
                                        :
                                        <ListaEquipeParticipante
                                            idWebconferencia={webconferencia.id}
                                            urlWebconferencia={webconferencia.url_webconferencia}
                                            resumoWebconferencia={webconferencia.resumo}
                                            atualizarResumo={(resumoNovo) => {
                                                webconferencia.resumo = resumoNovo;
                                                setWebconferencia({ ...webconferencia });
                                            }}
                                            estadoWebconferencia={webconferencia.sigla_estado}
                                            callback={() => setWebconferencia(null)} />
                                }
                            </>

                        }

                        {
                            idWebconferenciaSelecionada &&
                            <SttModal
                                title={tituloWebconferencia ? tituloWebconferencia : strings.tituloDetalhesPagina}
                                open={!!idWebconferenciaSelecionada}
                                maxWidth="lg"
                                fullWidth={true}
                                outModalClose={fecharModalDetalhes}
                                iconClose={fecharModalDetalhes}
                                children={
                                    <Detalhes id={idWebconferenciaSelecionada} fecharModalDetalhes={fecharModalDetalhes} abaProximasWebs={true} setBuscar={setBuscar} itemBiblioteca={itemBiblioteca} />
                                }
                            />
                        }
                    </div>
                    :
                    <>
                        <div className={classes.loading}>
                            <SttCircularProgress />
                        </div>
                        <SttCacheManager config={global.gConfig} onSync={loaded => {
                            const user = Cache.getUserInfo();
                            setUser(user);
                            setAuthenticated(loaded);
                        }} />
                    </>
            }
        </>
    );

};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.index.isAuthenticated,
        user: state.index.user,
        parametrosAlerta: state.alertaReducer.parametros,
        parametrosCarregando: state.carregandoReducer.parametros,
        abaAtiva: state.webconferenciaReducer.abaAtiva,
        parametrosPesquisa: state.webconferenciaReducer.parametrosPesquisa,
        desabilitarAbaAoVivo: state.webconferenciaReducer.desabilitarAbaAoVivo,
        pesquisarEventosAndamento: state.webconferenciaReducer.pesquisarEventosAndamento
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthenticated: (authenticated) => dispatch(setAuthenticatedAction(authenticated)),
        setUser: (user) => dispatch(setUserAction(user)),
        setOpen: (open) => dispatch(setOpenAction(open)),
        setPesquisarEventosAndamento: (pesquisar) => dispatch(setPesquisarEventosAndamentoAction(pesquisar)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interno);
